.page-title {
  background-color: #f1f1f1;
  min-height: 30px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* text-align: center; */
  align-items: center;
  min-height: 150px;
  gap: 0;
}
.page-title h2 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 44px;
  font-family: "Oswald", sans-serif;
}
.page-title p {
  font-size: 13px;
  font-weight: 500;
  font-family: "Oswald", sans-serif;
}
.contact-details {
  margin-top: 50px;
}
.contact-text {
  color: #111;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.contact-text:before {
  content: "";
  left: 0;
  bottom: 0;
  width: 40px;
  background-color: #82a321;
  position: absolute;
  height: 2px;
  display: inline-block;
}
.contact-box {
  background-color: #f7f7f7;
  padding: 40px 30px;
}
.contact-detail-box {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
}
.contact-detail-icon {
  height: 50%;
  display: flex;
  color: #82a321;
  border: 1px solid #82a321;
  padding: 10px;
  border-radius: 50%;
}

.contact-detail-icon svg {
  font-size: 25px;
}

.contact-detail-text p {
  margin-bottom: 0px;
  font-weight: 700;
  color: #111;
}
.contact-detail-text span {
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

/* Contact Form */
.contact-form form {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.contact-form form div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.contact-form form div input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #626262;
  width: 49%;
}
.contact-form form textarea {
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
}
.form-btn {
  width: 150px;
  padding: 15px 15px;
  border: none;
  background-color: #82a321;
  color: #fff;
  border-radius: 5px;
}
.form-btn:hover {
  border: 1px solid #82a321;
  background-color: #fff;
  color: #82a321;
}

@media screen and (max-width: 500px) {
  .contact-form form div {
    flex-direction: column;
  }
  .contact-form form div input {
    width: 100%;
    margin-bottom: 10px;
  }
  .contact-form form {
    gap: 0px;
  }
  .form-btn{
    margin-top: 20px;
}
}
