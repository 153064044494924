.offering-section1 {
  margin-top: 50px;
  position: relative;
}

.offering-section1 h1 {
  position: relative;
  padding-bottom: 5px;
  font-weight: 700;
}
.offering-section1 h1:before {
  content: "";
  position: absolute;
  width: 150px;
  height: 3px;
  background-color: #82a321;
  /* top: 0; */
  left: 0;
  bottom: 0;
}

.offering-section1 h3 {
  margin-top: 30px;
  font-weight: 700;
}

.why-prith {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
}
.why-prith h2 {
  display: flex;
  justify-content: center;
}
.why-prith h2 span {
  font-weight: 700;
}

.two-wheeler {
  margin-top: 100px;
}
.two-wheeler p {
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
}
.why-prith .col-lg-4 {
  border: 1px solid #d7d7d7;
}
.why-prith ul {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.offeringspecification {
  margin-top: 100px;
}
.offeringspecification h2 {
  position: relative;
}
.offeringspecification h2::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 3px;
  background-color: #82a321;
  /* top: 0; */
  left: 0;
  bottom: 0px;
}

/* tempo */
.tempo{
    margin-top: 100px;
}
.tempo-heading h2 {
  position: relative;
}
.tempo-heading h2::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 3px;
  background-color: #82a321;
  bottom: 0;
}
.tempo-img {
  position: relative;
  margin-top: 20px;
}
.tempo-img img {
  width: 100%;
  height: 300px;
  filter:  blur(0px);
  -webkit-filter: blur(10px);
  display: block;
}
.tempo-img .tempo-overlay{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7); 
    color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
}

.extra-heading h2{
    position: relative;
    color: gray;
    font-size: 25px;
}
.extra-heading h2::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 3px;
    background-color: #82a321;
    bottom: 0;
  }
  .productclone{
    width: 250px;
    height: 250px;
  }
  .productcolnetitle{
    font-size: 19px;
  }
@media screen and (max-width: 500px) {
  .two-wheeler p {
    font-size: 30px;
  }
  .productclone{
    width: 200px;
    height: 200px;
  }
}
