@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
}

/* ACEB1E */
/* #82a321 */