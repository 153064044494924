.home-main-banner {
  background-color: #111318;
  height: 100vh;
  display: flex;
  align-items: center;
}
.home-main-banner-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-control-prev {
  left: -61px !important;
}
.home-main-banner-left .banner-text h1 {
  font-size: 90px;
  font-weight: 400;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(169, 207, 56, 0.1);
  margin-bottom: 0;
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

.banner-text h2 {
  color: #aceb1e;
  letter-spacing: 2px;
  padding-left: 5px;
  font-size: 35px;
  font-weight: 800;
  margin-top: -20px;
}

.home-main-banner-right {
  text-align: center;
}
.home-main-banner-right img {
  width: 90%;
  opacity: 0.7;
}
.home-main-banner-right {
  position: relative;
}
.hightlight1 {
  position: absolute;
  top: 30%;
  left: 10%;
  cursor: pointer;
}
.hightlight2 {
  position: absolute;
  top: 70%;
  left: 10%;
  cursor: pointer;
}
.hightlight1:hover > .hightlight-text {
  display: block !important;
}
.hightlight2:hover > .hightlight-text {
  display: block !important;
}
.hightlight1 svg,
.hightlight2 svg {
  transform: rotate(90deg);
}
.hightlight-text {
  position: absolute;
  display: none !important;
  margin-top: 10px;
  width: 200px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 10px;
  border-radius: 5px;
  background-color: #26282f !important;
  transition: all 1s ease;
}
.hightlight-text p {
  margin: 0;
  font-weight: 500;
  color: #98a1bc;
  text-align: center;
  font-family: "Work Sans", sans-serif;
}
.hightlight-text span {
  color: lightgray;
  text-align: center;
  font-family: "Work Sans", sans-serif;
}

.prith-banner {
  display: flex;
  justify-content: center;
  margin: 50px 0px;
  padding: 0px 30px;
}
.prith-banner h1 {
  font-size: 70px;
  letter-spacing: 5px;
  color: gray;
}
.path-para1 {
  padding: 0px 30px;
  text-align: left;
  margin-top: 50px;
}
.para1-scooter {
  width: 130px;
}
.path-para1 h1 {
  font-weight: 700;
  font-family: "Archivo Black", sans-serif;
  font-size: 40px;
  letter-spacing: -1px !important;
  margin-bottom: 0;
}
.path-para1 h2 {
  font-family: "Archivo Black", sans-serif;
  font-weight: 500;
}
.path-para1-p {
  font-size: 17px;
  font-weight: 600;
}
.bike {
  margin-top: -50px;
}

/* Specification */

.specification-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 100px;
}
table {
  border: none !important;
}
/* home card */
.home-card {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 30px;
  border-radius: 20px;
}
.home-card .row {
  border: 1px solid lightgray;
  border-radius: 10px;
}
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  padding: 30px 20px;
  border-right: 1px solid lightgray;
}
.home-card .card-body {
  padding: 0;
}
.card img {
  width: 100px;
}
.card .card-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0px 10px;
}
.card-title {
  font-weight: 600;
  font-size: 17px;
}

/* Features */
.feature-section {
  border: 1px dashed #d7d7d7;
  max-width: 1200px;
  padding: 20px;
}
.feature-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
}
.feature-heading img {
  height: 15px;
}
.feature-box {
  border: 1px dashed #d7d7d7;
  padding: 40px;
  display: flex;
  gap: 20px;
}
.feature-box:hover > .feautre-number span {
  color: #82a321;
}
.feautre-number span {
  font-weight: 600;
  color: gray;
  font-family: "Teko", sans-serif;
  font-size: 20px;
}

.feature-text ul {
  margin: 0;
  padding: 0;
}
.feature-text p {
  margin-bottom: 5px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.feature-text span {
  color: gray;
  font-weight: 400;
}

@media screen and (max-width: 992px) {
  .home-main-banner .row {
    padding: 150px 0;
    flex-direction: column-reverse;
  }
  .home-main-banner-left {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0px 50px;
  }
  .specification-heading img {
    width: 300px;
  }
  .specification-heading {
    display: flex;
    /* flex-direction: column;   */
    justify-content: center;
    align-items: center;
    gap: 0px;
    margin-top: 100px;
    margin-bottom: 30px;
  }
  .home-card .home-card-line {
    border-right: 0px solid lightgray;
  }
  .feature-heading img {
    width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .bike {
    margin-top: 50px;
  }
  .slider-div {
    display: flex !important;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .slider-div .slider-text {
    width: 100%;
    padding: 0px 20px;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .slider-div img {
    width: 200px;
  }
  .custom-slider-btns {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: -15px;
  }
  .custom-slider-btns button {
    font-size: 30px;
    border: none;
    background-color: transparent;
  }

  .home-banner-imgsection {
    display: none;
  }
  .home-banner {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
    padding-top: 0px !important;
  }
  .mobile-parth {
    display: block;
  }
  .home-main-banner .banner-text h1 {
    font-size: 60px;
    font-weight: 400;
  }
  .home-banner .banner-text h2 {
    font-size: 40px;
    margin-top: -10px;
    font-weight: 600;
  }
  .hightlight1,
  .hightlight2 {
    display: none;
  }

  .feature-heading img {
    display: none;
  }

  /* specification */
  .specification-heading img {
    display: none;
  }
  td {
    width: 50%;
  }
  .carousel-control-prev {
    left: 0px !important;
  }
}
